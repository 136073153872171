import styled from 'styled-components'
import { BreakPoint } from '../variables/'

export const BoxPadding = styled.div`
	width: 100%;
	padding: 25px 35px;
	@media ${BreakPoint.md} {
		padding: 60px;
	}
`
