import React from "react"
import styled from 'styled-components'

import { Field } from "../../styles/"

const RadioGroup = styled.div`
	display: flex;
	flex-wrap: wrap;
`
const RadioItem = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin: 10px;
	input {
		margin-right: 5px;
	}
`

export const CheckField = ({ dataName, label, name, onChange, options, required, type, value }) => {

	const slugify = str =>
	str
		.toLowerCase()
		.trim()
		.replace(/[^\w\s-]/g, '')
		.replace(/[\s_-]+/g, '-')
		.replace(/^-+|-+$/g, '');

	

	return (
		<Field short={ true }>
			<p>{label}{required && '*'}</p>
			<RadioGroup>
				{options.map((option, i) => {
					const id = Math.floor(Math.random() * 10000000000) + '-' + slugify(option.value)
					return (
						<RadioItem key={i}>
							<input 
								type={ type ?? 'checkbox' } 
								id={ id } 
								name={ name }  
								value={ option.value } 
								onChange={ onChange } 
								required={ type === 'radio' ? required : false }
								checked={ value !== undefined && value.includes(option.value) ? 'checked' : null }/>
							<label htmlFor={ id }>
								{ option.label }
							</label>
						</RadioItem>
					)
				})}
			</RadioGroup>
		</Field>
	)
}
